@import '../../../styles/common/variables.scss';

// .container-incopatibilidad {
//     width: 100%;

// }

.container-documentos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    background-color: $light-gray;

}

.btn-load-file-rd {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 5px 8px;
    margin: 0px;
    border-radius: 6px;
    background-color: $accent;
    color: white;
}

p {
    margin: 0px;
}

.fieldset-container {
    border: 1px solid #172E56;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    &.error {
        border: 1px solid red;
        color: red;
    }

    legend {
        margin-top: -20px;
        padding-bottom: 8px;
        display: inline;
        font-weight: 500;
        font-size: 21px;


        .legend-text {
            display: inline-block;
            background-color: white;
            color: $accent;

        }
    }

}

.container-lopd {
    padding: 15px;
    background-color: $light-gray;
    text-align: justify;
    margin-top: 40px;
    margin-bottom: 40px;
    color: rgb(165, 165, 165);
}

.incompatibility-status {
    text-align: center;
}

.container-title-incompatibilidades {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.btn-disabled {
    background-color: #B1B1B1;
}

.name-and-document-container {
    display: flex;
    gap: 8px;

}

.column-name {
    font-weight: bolder;
    margin-bottom: 6px;
}

.icon-file-rd {
    padding: 3px;
    width: 16px;
    height: 18px;

    // border: 1px solid $accent;
    &:hover {
        box-shadow: $boxshadow-light;
        cursor: pointer;
        width: 16px;
        height: 18px;
    }

}
.li-operational-description {
    list-style-type: disc !important;
    list-style-position: inside !important;
    // margin-left: 20px;
}
.no-documents {
    color: #C98722;
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: #EEEEEE;
    padding: 10px;
}
.title-incompatibilidades {
    color: $accent;
    font-weight: 300;
}

.container-validations-observations {
    margin-top: 40px;
    margin-bottom: 40px;
}

.name-doc-incopatibilidad-rd {
    // max-width: 300px;
    display: flex;

}

.container-operational-description {
    margin-top: 40px;
    margin-bottom: 40px;
}

.container-next-actions {
    margin-top: 40px;
    margin-bottom: 40px;
}

.selected-files-rd {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $light-gray;

}

.selected-file-text-rd {
    color: $accent;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.container-name-file-rd {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid $accent;
    padding: 6px;
    width: fit-content;
    font-size: 12px;
    background-color: white;

}
.block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid $accent;
    padding: 6px;
    width: fit-content;
    font-size: 12px;
    color: rgb(133, 131, 131);
    background-color: #EEEEEE;

}

.container-btn-send-documents-rd {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}

.container-file-info {
    background-color: #D1DDF1;
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 3px;
    padding: 21px;
}

.name-and-icon-information {
    display: flex;
    gap: 10px;
    align-items: center;
    // width: 264px;
}
.body-modal-observaciones-rd {
    background-color: $light-gray;
}

.title-obsevaciones-rd {
    color: $accent;
    font-weight: 100;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bolder;

}

.text-obsevaciones-error-rd {
    text-align: center;
   
}

.text-body-modal-observaciones-rd {
    text-align: justify;
   
}


.header-modal-observaciones-rd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-files-list-rd {
    border-left: solid 3px white;
    display: flex;
    flex-wrap: wrap;
    padding-left: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    gap:5px
}

.container-audit-status-summary {
    margin-top: 50px;
}
@media (max-width: 768px) {

    .name-file-rd {
        font-size: 10px;
    }

    .selected-file-text-rd {
        font-size: 14px;
    }

    .container-name-file-rd {
        margin-bottom: 10px;
    }
}
.container-btn-send {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.limit {
    font-size: 10px;
}
.limite-size{
    font-size: 10px;

}


.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px; 
    font-weight: bold;
    color: #000;
}

/* Colores de progreso */
.progress-bar-container {
    width: 100%;
    background-color: #e0e0e0; 
    border-radius: 0.375rem; 
    position: relative;
    padding-top: 1px;
    padding-bottom: 1px;
    height: 11px; 
    margin: 0;
}

.progress-bar {
    height: 100%;
    border-radius: 0.375rem; /* Bordes redondeados */
    transition: width 0.3s ease-in-out;
}

/* Colores de progreso */
.progress-green {
    background-color: #75af77; /* Verde */
}

.progress-light-green {
    background-color: #75af77; /* Verde claro */
}

.progress-yellow {
    background-color: #f3e882; /* Amarillo */
}

.progress-orange {
    background-color: #f7ba5e; /* Naranja */
}

.progress-red {
    background-color: #fa8f87; /* Rojo */
}
